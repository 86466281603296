import React, { ReactElement, useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import { IViewportSizeContext, IViewportSizeProviderProps } from './types';
import { getDevice } from './utils';
import { ViewportSizeContext } from './context';

export const ViewportSizeProvider = ({ breakpoints, children }: IViewportSizeProviderProps): ReactElement => {
  const { ref, width } = useResizeDetector<HTMLDivElement>({
    handleHeight: false,
    handleWidth: true,
    refreshMode: 'throttle',
    refreshRate: 200,
  });

  const [value, setValue] = useState<IViewportSizeContext | undefined>(
    !width
      ? undefined
      : {
          device: getDevice({ breakpoints, width })!,
        }
  );

  useEffect(() => {
    const device = getDevice({ breakpoints, width });
    if (device && device !== value?.device) setValue({ device });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return (
    <>
      <div ref={ref} />
      {value && <ViewportSizeContext.Provider value={value}>{children}</ViewportSizeContext.Provider>}
    </>
  );
};
