import React, { ComponentType, ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from 'providers/AuthProvider/AuthProvider';
import { ViewportSizeProvider } from 'providers/ViewportSizeProvider';

export const Context: ComponentType<{ children: ReactNode }> = ({ children }) => (
  <ViewportSizeProvider>
    <BrowserRouter>
      <AuthProvider>{children}</AuthProvider>
    </BrowserRouter>
  </ViewportSizeProvider>
);
