import React, { Suspense } from 'react';
import { ErrorBoundary } from '@appsignal/react';
import { createRoot } from 'react-dom/client';
import { pdfjs } from 'react-pdf';
import pdfWorkerSrc from 'pdfjs-dist/build/pdf.worker.min.js';
import 'normalize.css';
import 'allotment/dist/style.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import * as serviceWorker from './serviceWorker';

import '@dknmz/mp_ui/dist/css/grid.css';
import '@dknmz/mp_ui/dist/tokens/web/mpui.scss';
import '@dknmz/mp_ui/dist/tokens/web/scss/themeDark.scss';

import './index.scss';
import { Integrations } from './js/application/_global_context/Integrations';
import { Context } from './js/application/_global_context/Context';
import { RouteSwitch } from './js/application/_global_context/RouteSwitch';
import { appsignalInstance } from './js/config/_global_context/appsignal';
import { AppSpinner } from './js/containers/_global_context/AppSpinner';

// https://github.com/GoogleChromeLabs/container-query-polyfill
if (!('container' in document.documentElement.style)) {
  import('container-query-polyfill');
}

pdfjs.GlobalWorkerOptions.workerSrc = pdfWorkerSrc;
const container = document.getElementById('root');
const root = createRoot(container as Element);
root.render(
  <Context>
    <ErrorBoundary action={'IntegrationsBoundary'} instance={appsignalInstance}>
      <Integrations />
    </ErrorBoundary>
    <ErrorBoundary action={'RouteSwitchBoundary'} instance={appsignalInstance}>
      <Suspense fallback={<AppSpinner />}>
        <RouteSwitch />
      </Suspense>
    </ErrorBoundary>
  </Context>
);
serviceWorker.unregister();
