import {
  CurrencyCode,
  CurrencyFragment,
  FlowStepConditionInputAttributeDataType,
  IndexDataViewsMetadataQuery,
  MetaModelAttributeDataType,
  MetaModelResourceType,
} from 'graphql-api/generated-types';
import { InferConnectionNode } from '../utils';

type DataType = FlowStepConditionInputAttributeDataType | MetaModelAttributeDataType;
export type ReferenceTypesKeys = Exclude<DataType, (typeof ReferenceValueInputTypes)['Date']>;

export interface ReferenceValueInputProps {
  autoFocus?: boolean;
  baseCurrency: CurrencyCode;
  currencies: Array<CurrencyFragment>;
  dataType?: DataType;
  disabled?: boolean;
  errorMessages: string[] | undefined;
  label?: string;
  metaModels?: Array<InferConnectionNode<IndexDataViewsMetadataQuery>>;
  onChange: (value?: string) => void;
  path?: string | string[];
  required?: boolean;
  rootResourceType?: MetaModelResourceType;
  selectOptions?: string[];
  value?: string;
  withSubmitWrapper?: boolean;
  wrapperClassName?: string;
  wrapperCustomAttrs?: object;
}

export const ReferenceValueInputTypes = {
  ...FlowStepConditionInputAttributeDataType,
  ...MetaModelAttributeDataType,
};

export const referenceTypes: Record<ReferenceTypesKeys, DataType[]> = {
  [ReferenceValueInputTypes.Boolean]: [
    FlowStepConditionInputAttributeDataType.Boolean,
    MetaModelAttributeDataType.Boolean,
  ],
  [ReferenceValueInputTypes.Datetime]: [
    FlowStepConditionInputAttributeDataType.Datetime,
    MetaModelAttributeDataType.Date,
    MetaModelAttributeDataType.Datetime,
  ],
  [ReferenceValueInputTypes.Decimal]: [
    FlowStepConditionInputAttributeDataType.Decimal,
    MetaModelAttributeDataType.Decimal,
  ],
  [ReferenceValueInputTypes.Enum]: [FlowStepConditionInputAttributeDataType.Enum, MetaModelAttributeDataType.Enum],
  [ReferenceValueInputTypes.File]: [MetaModelAttributeDataType.File],
  [ReferenceValueInputTypes.Integer]: [
    FlowStepConditionInputAttributeDataType.Integer,
    MetaModelAttributeDataType.Integer,
  ],
  [ReferenceValueInputTypes.Jsonb]: [MetaModelAttributeDataType.Jsonb],
  [ReferenceValueInputTypes.Money]: [FlowStepConditionInputAttributeDataType.Money, MetaModelAttributeDataType.Money],
  [ReferenceValueInputTypes.Object]: [FlowStepConditionInputAttributeDataType.Object],
  [ReferenceValueInputTypes.String]: [
    FlowStepConditionInputAttributeDataType.String,
    MetaModelAttributeDataType.String,
  ],
};
